import React, { useContext } from 'react';

import { FormContext } from '../context';

import loadComponents from '../../Loadable';

const ButtonInput = loadComponents('button-input');

export default function Submit({ label }) {
  const { loading, processing, sent } = useContext(FormContext);
  const isDisabled = loading || processing || sent;
  return (
    <ButtonInput
      value={label}
      processing={processing}
      disabled={isDisabled}
      title={label}
      aria-label={label}
      className="form-btn"
      primary
    />
  );
}
